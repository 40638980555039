import React, { useEffect, useState, Fragment } from "react"
import {
    Button,
    CameraText,
    EmptyList,
    Loader,
    ProfileImage,
    ProfileName,
    InfoLine
} from "./Components"

import yes from "./images/ic-yes-36.png"
import lab from "./images/ic-lab-36.png"
import {
    findMyDevices,
    initClient,
    getUserInfo,
    fetchUserInfo,
    fetchDeviceList,
    updateCell,
    getDate
} from "./helpers"

const TestLab = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const [deviceId, setDeviceId] = useState(urlParams.get("deviceId"))
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [profile, setProfile] = useState({ username: "", image: "" })
    const [myDevices, setMyDevices] = useState([])
    const [isLoadingLogin, setIsLoadingLogin] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [phoneInfo, setPhoneInfo] = useState({
        currentUser: "",
        previousUser: "",
        phoneModel: "",
        osVersion: "",
        phoneId: ""
    })

    const updateSigninStatus = () => {
        const gapi = window.gapi
        var isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get()

        if (isSignedIn) {
            setIsLoggedIn(true)
            setIsLoadingLogin(false)
            getData(deviceId)
        } else {
            setIsLoggedIn(false)
            setIsLoadingLogin(false)
            setIsLoadingLogin(false)
            setPhoneInfo({
                currentUser: "",
                previousUser: "",
                phoneModel: "",
                osVersion: "",
                phoneId: ""
            })
        }
    }

    useEffect(() => {
        initClient().then(data => {
            updateSigninStatus()
        })
    }, [])

    const getData = async deviceIdInternal => {
        setIsLoading(true)
        const result = await Promise.all([fetchUserInfo(), fetchDeviceList()])
        const [dataProfile, dataDevices] = result
        const profileData = getUserInfo(dataProfile)

        setProfile({
            username: profileData.username,
            image: profileData.profileImage
        })

        setMyDevices(
            findMyDevices(dataDevices.result.values, profileData.username)
        )

        if (deviceIdInternal) {
            await toggleDevice(
                dataDevices.result.values,
                profileData.username,
                deviceIdInternal
            )
        }

        setIsLoading(false)
    }

    const toggleDevice = async (devices, currentOwner, device) => {
        const currentRow = devices.filter(row => {
            return row[0] === device
        })

        const currentRowIndex = devices.findIndex(row => {
            return row[0] === device
        })

        let username = currentOwner === currentRow[0][4] ? "Lab" : currentOwner
        const previousUser = currentRow[0][4]

        await updateCell(
            `ios!E${currentRowIndex + 1}:G${currentRowIndex + 1}`,
            [username, getDate(), previousUser]
        )

        const currentUser = username
        const phoneModel = currentRow[0][1]
        const osVersion = currentRow[0][2]
        const phoneId = currentRow[0][0]

        setPhoneInfo({
            currentUser,
            previousUser,
            phoneModel,
            osVersion,
            phoneId
        })

        await getData()
    }

    const handleAuthClick = () => {
        setIsLoadingLogin(true)
        const gapi = window.gapi
        var auth = gapi.auth2.getAuthInstance()
        auth.signIn().then(() => {
            updateSigninStatus()
        })
    }

    const handleSignoutClick = () => {
        const gapi = window.gapi
        gapi.auth2
            .getAuthInstance()
            .signOut()
            .then(() => {
                setIsLoggedIn(false)
            })
    }

    let checkIn = false
    if (phoneInfo.currentUser === profile.username) {
        checkIn = true
    }

    const CheckInInfo = () => {
        return (
            <div className="device-info">
                <img src={lab} className="delivery-icon delivery-pink" alt="" />
                <InfoLine value="Thank you." className="thank-you" />
                <InfoLine
                    value="I'm back in the lab!"
                    className="success-message"
                />
            </div>
        )
    }

    const CheckOutInfo = () => {
        return (
            <div className="device-info">
                <img src={yes} className="delivery-icon" alt="" />
                <InfoLine value="Ready for test." className="success-message" />
                <div className="device-details-row">
                    <InfoLine
                        value={`${phoneInfo.phoneId}. ${phoneInfo.phoneModel}`}
                        className="device-name"
                    />{" "}
                    -
                    <InfoLine value={phoneInfo.osVersion} className="os-info" />
                </div>

                <InfoLine
                    value={`Previous — ${phoneInfo.previousUser}`}
                    className="prev-owner"
                />
            </div>
        )
    }

    const deviceInfo = checkIn ? <CheckOutInfo /> : <CheckInInfo />

    const MyDevices = () => {
        return (
            <div className="my-device-list-container">
                {myDevices.map((device, index) => {
                    return (
                        <div className="my-device-list" key={index}>
                            {device[0] + ". " + device[1] + " "}
                            {
                                <div
                                    className="button red-button"
                                    onClick={() => {
                                        getData(device[0])
                                    }}
                                >
                                    Return
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    const DeviceList = () => {
        return myDevices.length > 0 ? (
            <Fragment>
                <div className="my-device-title">My devices</div>
                <MyDevices />
            </Fragment>
        ) : (
            <Fragment>
                <EmptyList />
                <CameraText />
            </Fragment>
        )
    }

    const loggedIn = (
        <div className="logged-in-wrapper">
            <ProfileImage image={profile.image} />
            <ProfileName username={profile.username} />
            <Button label="Sign out" action={handleSignoutClick} />
            {deviceId && (
                <Button
                    label="Hide message"
                    action={() => {
                        window.history.pushState({}, "", "/")
                        setDeviceId("")
                    }}
                />
            )}
            {isLoading ? <Loader /> : deviceId ? deviceInfo : <DeviceList />}
        </div>
    )

    const loggedOut = (
        <div className="logged-out-wrapper">
            <InfoLine
                value="Who's borrowing the device?"
                className="login-message"
            />
            <Button label="Sign in" action={handleAuthClick} />
        </div>
    )

    return (
        <Fragment>
            <div className="circle big" />
            <div className="circle small" />
            <div className="container">
                <a href="/">
                    <div className="logo-container">
                        <svg width={76} height={28} viewBox="0 0 844 240">
                            <title>{"agens"}</title>
                            <path
                                d="M780 122c13.87 0 24-4 24-12 0-10-19.848-13.485-36-18-25.612-7.16-48-18.295-48-46 0-28 30.22-46 60-46 25.225 0 46 8 60 20l-26 26c-12.005-9-26-10-34-10-14 0-20 8-20 12 0 10 16.425 13.075 34 18 19.88 5.571 50 14 50 42s-28 52-64 52c-25.975 0-54-12-64-24l28-28c12 12 26 14 36 14zM592 70v86h-40V20l23.108-2.31C587.393 6.671 604.09 0 624 0c43.764 0 72 32.235 72 72v84h-40V70c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32zM411.005 96c5.992 15.26 20.3 26 36.995 26 10.027 0 19.191-3.874 26.211-10.273l34.314 20.588C493.856 149.272 472.181 160 448 160c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80c0 5.48-.55 10.83-1.6 16H411.005zm0-32h73.99c-5.992-15.26-20.3-26-36.995-26-16.696 0-31.003 10.74-36.995 26zm-108.199 85.974C291.313 156.362 278.081 160 264 160c-44.183 0-80-35.817-80-80s35.817-80 80-80c18.557 0 35.638 6.318 49.21 16.921L344 20v140.333C344 204.332 308.332 240 264.333 240c-22 0-41.916-8.917-56.333-23.334l28.167-28.166c7.208 7.208 17.166 11.667 28.166 11.667 22 0 39.834-17.835 39.834-39.834 0-.111 0-.222-.002-.333H304v-3.334a39.697 39.697 0 0 0-1.194-6.692zM264 122c22.091 0 40-18.804 40-42s-17.909-42-40-42-40 18.804-40 42 17.909 42 40 42zM129.21 16.921L160 20v136h-40v-6.703C108.233 156.104 94.571 160 80 160c-44.183 0-80-35.817-80-80S35.817 0 80 0c18.557 0 35.638 6.318 49.21 16.921zM80 122c22.091 0 40-18.804 40-42s-17.909-42-40-42-40 18.804-40 42 17.909 42 40 42z"
                                fill="#282878"
                                fillRule="evenodd"
                            />
                        </svg>
                        <div className="testlab">– TESTLAB</div>
                    </div>
                </a>
                <div>
                    {isLoadingLogin ? (
                        <Loader />
                    ) : isLoggedIn ? (
                        loggedIn
                    ) : (
                        loggedOut
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default TestLab
