import { config } from "./config"

export const initClient = () => {
    return new Promise(resolve => {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = "https://apis.google.com/js/api.js"
        script.onload = e => {
            const gapi = window.gapi

            gapi.load("client:auth2", () => {
                gapi.client
                    .init({
                        apiKey: config.apiKey,
                        clientId: config.clientId,
                        discoveryDocs: config.discoveryDocs,
                        scope: config.scopes,
                        cookiepolicy: "none"
                    })
                    .then(() => {
                        resolve()
                    })
            })
        }
        document.getElementsByTagName("head")[0].appendChild(script)
    })
}

export const updateCell = (range, value) => {
    const gapi = window.gapi
    return gapi.client.sheets.spreadsheets.values.update({
        spreadsheetId: config.spreadsheetId,
        range: range,
        valueInputOption: "RAW",
        resource: {
            values: [value]
        }
    })
}

export const fetchUserInfo = () => {
    const gapi = window.gapi
    return gapi.client.request({
        path:
            "https://people.googleapis.com/v1/people/me?personFields=names,photos"
    })
}

export const fetchDeviceList = () => {
    const gapi = window.gapi
    return gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: config.spreadsheetId,
        range: "ios!A1:E100"
    })
}

export const getUserInfo = people => {
    const json = JSON.parse(people.body)
    let username = json.names[0].displayName
    const profileImage = json.photos[0].url

    return {
        username,
        profileImage
    }
}

export const findMyDevices = (devices, username) => {
    return devices.reduce((accumulator, currentValue, index) => {
        if (currentValue[4] === username) {
            accumulator.push([...currentValue, index])
        }
        return accumulator
    }, [])
}

export const getDate = () => {
    return (
        new Date().getDate() +
        "." +
        (new Date().getMonth() + 1) +
        " " +
        new Date().getFullYear() +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes()
    )
}
